import React, { useEffect, useState } from 'react';
import shuffleArray from '../../../../../utils/shuffleArray';
import cl from './LettersCard.module.css';

const LettersCard = ({ wordObj }) => {
    const [typedWord, setTypedWord] = useState([]);
    const [letterOptions, setLetterOptions] = useState([]);
    const [isCorrect, setIsCorrect] = useState(null);

    const { word, translations, synonyms } = wordObj;

    const handleLetterClick = (lObj) => {
        if (!lObj || isCorrect) return;
        if (!typedWord.some((w) => w.idx === lObj.idx)) {
            setTypedWord([...typedWord, lObj]);
            setLetterOptions(letterOptions.filter((w) => w.idx !== lObj.idx));
        } else {
            setTypedWord(typedWord.filter((w) => w.idx !== lObj.idx));
            setLetterOptions([...letterOptions, lObj]);
        }
    };

    useEffect(() => {
        setTypedWord([]);
        setLetterOptions(
            shuffleArray(
                word
                    .toLowerCase()
                    .split('')
                    .map((letter, idx) => ({ letter, idx }))
            )
        );
        setIsCorrect(null);
    }, [word]);

    useEffect(() => {
        if (typedWord.length === word.length) {
            const flag =
                typedWord.map((lObj) => lObj.letter).join('') ===
                word.toLowerCase();
            setIsCorrect(flag);
        } else {
            setIsCorrect(null);
        }
    }, [typedWord]);

    return (
        <div className={cl.lettersCard}>
            <div
                className={`${cl.answerCont} ${
                    isCorrect !== null
                        ? isCorrect
                            ? cl.correct
                            : cl.wrong
                        : ''
                }`}
            >
                {Array(word.length)
                    .fill('')
                    .map((_t, i) => (
                        <span
                            className={cl.letter}
                            key={`answer_${i}`}
                            onTouchEnd={(e) => {
                                e.preventDefault();
                                handleLetterClick(typedWord[i]);
                            }}
                            onClick={() => handleLetterClick(typedWord[i])}
                        >
                            {typedWord[i]?.letter || ''}
                        </span>
                    ))}
            </div>
            <div className={cl.translation}>
                <p className={cl.translationText}>{translations.join(', ')}</p>
            </div>
            {isCorrect && synonyms.length && (
                <p className={cl.infoText}>{synonyms.join(', ')}</p>
            )}
            <div
                className={cl.lettersCont}
                style={{
                    opacity: isCorrect ? 0 : 1,
                    pointerEvents: isCorrect ? 'none' : 'all',
                }}
            >
                {letterOptions.map((lObj) => (
                    <div
                        className={`${cl.letter} ${
                            typedWord.some((w) => w.idx === lObj.idx)
                                ? cl.active
                                : ''
                        }`}
                        onTouchEnd={(e) => {
                            e.preventDefault();
                            handleLetterClick(lObj);
                        }}
                        onClick={() => handleLetterClick(lObj)}
                        key={`option_${lObj.idx}`}
                    >
                        {lObj.letter}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default LettersCard;
