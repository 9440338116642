import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import onboardingStore from '../../../../../../store/onboarding';
import { Button, Tooltip } from '../../../../../../teacherComponents';
import { Chapters } from '../../../../data/constants';
import DemoPageLoader from '../../../../../../components/UI/DemoPageLoader/DemoPageLoader';
import { ContentModal } from '..';
import { OnboardingKeys } from '../../../../../../data/onboardingKeys';
import {
    BubblePositions,
    OnboardingWrapper,
} from '../../../../../../teacherComponents/Onboarding';
import Modal from '../../../../../../components/UI/Modal/Modal';
import { ReactComponent as IconDictionary } from '../../../../../../assets/svg/lessons-dictionary.svg';
import { ReactComponent as IconExercise } from '../../../../../../assets/svg/lessons-exercise.svg';
import { ReactComponent as IconSituation } from '../../../../../../assets/svg/lessons-situation.svg';
import { ReactComponent as IconTrack } from '../../../../../../assets/svg/lessons-track.svg';
import { ReactComponent as IconFile } from '../../../../../../assets/svg/lessons-file.svg';
import cl from './AddChapter.module.css';

const ChapterOptions = [
    {
        type: Chapters.Exercise,
        onboardingKey: OnboardingKeys.ExercisesContentSelect,
    },
    {
        type: Chapters.Situation,
        onboardingKey: OnboardingKeys.SituationsContentSelect,
    },
    {
        type: Chapters.Track,
        onboardingKey: OnboardingKeys.VideosContentSelect,
    },
    {
        type: Chapters.Dictionary,
        onboardingKey: OnboardingKeys.DictionaryContentSelect,
    },
];

const AddChapter = ({
    onAdd,
    alreadyAdded,
    onFileSelect,
    isLoading,
    isError,
    resetError,
    forceOpen,
}) => {
    const { t } = useTranslation();
    const buttonsRef = useRef();
    const optionsRef = useRef();

    const [isExpanded, setIsExpanded] = useState(false);
    const [isSituationGenerated, setIsSituationGenerated] = useState(false);
    const [currentType, setCurrentType] = useState('');

    const renderIcon = (type) => {
        switch (type) {
            case Chapters.Track:
                return <IconTrack />;
            case Chapters.Situation:
                return <IconSituation />;
            case Chapters.Exercise:
                return <IconExercise />;
            case Chapters.Dictionary:
                return <IconDictionary />;
            case Chapters.File:
                return <IconFile />;
            default:
                return null;
        }
    };

    const getClassName = () => {
        const classNames = [cl.buttonContainer];
        if (isExpanded) classNames.push(cl.expanded);

        return classNames.join(' ');
    };

    const handleAddButtonClick = () => {
        setIsExpanded(!isExpanded);
    };

    const handleClose = () => {
        if (forceOpen) return;
        setIsExpanded(false);
    };

    const onSituationAdd = () => {
        setIsSituationGenerated(true);
        handleClose();
    };

    const renderShadow = () => {
        const optionsCont = optionsRef.current;
        if (!optionsCont || !isExpanded) return null;

        const coords = optionsCont.getBoundingClientRect();
        const style = {
            top: coords.top,
            left: coords.left,
            width: coords.width,
            height: coords.height,
        };

        return <div className={cl.shadow} style={style} />;
    };

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (buttonsRef.current && !buttonsRef.current.contains(e.target)) {
                handleClose();
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [buttonsRef]);

    useEffect(() => {
        setIsExpanded(forceOpen);
    }, [forceOpen]);

    useEffect(() => {
        const handleResize = () => {
            setIsExpanded(false);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            <ContentModal
                type={currentType}
                onClose={() => setCurrentType('')}
                onAdd={onAdd}
                alreadyAdded={alreadyAdded}
                onFileSelect={onFileSelect}
                onSituationAdd={onSituationAdd}
            />
            <div
                ref={buttonsRef}
                className={getClassName()}
                onClick={handleAddButtonClick}
            >
                {isLoading ? (
                    <DemoPageLoader size={24} color={'var(--white)'} />
                ) : (
                    <p className={cl.buttonText}>
                        {t('exercises.add_chapter')}
                    </p>
                )}
                {!isLoading && isError && (
                    <Tooltip
                        variant={'error'}
                        text={t('exercises.chapter_conflict')}
                        onHide={resetError}
                        tailPosition="center"
                        style={{
                            top: -45,
                            left: '50%',
                            transform: 'translateX(-50%)',
                        }}
                    />
                )}
                <OnboardingWrapper
                    onboardingKey={onboardingStore.isOnboarding(
                        OnboardingKeys.ExercisesContentSelect
                    )}
                    title={t('teacher_onboarding.select_chapter_type')}
                    subtitle={t('teacher_onboarding.press_to_continue')}
                    delay={600}
                    bubblePosition={{
                        x: BubblePositions.x.Center,
                        y: BubblePositions.y.Top,
                    }}
                    bubbleAutoWidth
                >
                    <div className={cl.options} ref={optionsRef}>
                        {ChapterOptions.map((o) => (
                            <div
                                key={o.type}
                                className={`${cl.optionContainer} ${cl[o.type]}`}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setIsExpanded(false);
                                    setCurrentType(o.type);
                                    onboardingStore.moveToNextComponent();
                                }}
                            >
                                <div className={cl.iconContainer}>
                                    {renderIcon(o.type)}
                                </div>
                                <div className={cl.optionText}>
                                    <p className={cl.title}>
                                        {t(`exercises.add_${o.type}`)}
                                    </p>
                                </div>
                            </div>
                        ))}
                        {renderShadow()}
                    </div>
                </OnboardingWrapper>
            </div>
            <Modal
                visible={isSituationGenerated}
                setVisible={() => setIsSituationGenerated(false)}
                className={cl.intro}
            >
                <p className={cl.modalTitle}>
                    {t(`exercises.situation_lesson_queue_title`)}
                </p>
                <div className={cl.modalSubtitle}>
                    {t(`exercises.situation_lesson_queue_subtitle`)}
                </div>

                <Button
                    variant={'purple'}
                    text={t('teacher_onboarding.close_button')}
                    onClick={() => setIsSituationGenerated(false)}
                />
            </Modal>
        </>
    );
};

export default observer(AddChapter);
