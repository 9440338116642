import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useFetching } from '../../../../../../hooks/useFetching';
import ExerciseService from '../../../../../../api/ExerciseService';
import ProgressService from '../../../../../../api/ProgressService';
import TrackService from '../../../../../../api/TrackService';
import exercisesStore from '../../../../store/exercisesStore';
import studentsStore from '../../../../../TeacherStudents/store/studentsStore';
import lessonsStore from '../../../../../TeacherLessons/store/lessonsStore';
import teachersStore from '../../../../../Teachers/store/teachersStore';
import exercisesPlayer from '../../../../store/exercisesPlayer';
import { Loader } from '../../../../../../UI';
import {
    AdvancedQuizPreview,
    ClozePreview,
    SubstitutionPreview,
    MultichoicePreview,
    QuizPreview,
    SentenceOrderPreview,
    SummaryPreview,
    WordsOrderPreview,
    FreeFormQuestionsPreview,
} from './components';
import {
    GrammarExerciseTypes,
    MediaExerciseTypes,
} from '../../../../data/constants';
import { LanguageLevels, TicksInSecond } from '../../../../../../data/common';
import { EditExerciseModal, ExercisePlayer, ExercisePlayerControls } from '..';
import { Button, Input } from '../../../../../../teacherComponents';
import {
    Chapters,
    StudentExerciseStatuses,
    Views,
} from '../../../../../TeacherLessons/data/constants';
import cl from './Preview.module.css';

const Preview = ({ exerciseId, onChange, hideEdit }) => {
    const { t, i18n } = useTranslation();

    const { currentTeacher } = teachersStore;
    const { currentLesson } = lessonsStore;
    const { currentStudent, chaptersModalType, editMode, showAnswers, view } =
        exercisesStore;

    const containerRef = useRef();

    const isStudents = view === Views.Students;
    const isTeacher = view === Views.Teachers && currentTeacher;

    const currentStudentChapters = isStudents
        ? exercisesStore.currentStudentChapters
        : {};

    const [isEmptyComment, setIsEmptyComment] = useState(false);

    const scrollToBottom = () => {
        if (containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    };
    const isExerciseHaveAnswers = () => {
        return ![
            MediaExerciseTypes.FreeFormQuestions,
            GrammarExerciseTypes.StatementsTransformation,
            MediaExerciseTypes.Presentation,
        ].includes(exerciseObj.type);
    };

    const isRenderEditButton = () => {
        if (hideEdit) return false;
        if (chaptersModalType === Chapters.Exercise) return false;
        if (isTeacher) return false;
        if (currentStudent && currentLesson) return false;
        return true;
    };

    const [exerciseObj, setExerciseObj] = useState({});
    const [results, setResults] = useState();
    const [status, setStatus] = useState('');
    const [comment, setComment] = useState('');

    const [getExerciseData, dataLoading] = useFetching(async () => {
        if (!exerciseId) return;
        const { data } = await ExerciseService.getExercise({
            id: exerciseId,
        });
        if (data.trackId && data.trackInterval) {
            await getTrack({
                trackId: data.trackId,
                trackInterval: data.trackInterval,
            });
        } else {
            exercisesPlayer.resetMediaParams();
        }
        setExerciseObj(data);
    });

    const [getTrack, trackLoading] = useFetching(
        async ({ trackId, trackInterval }) => {
            const { data } = await TrackService.getTrack(trackId);
            const rangeInTicks = trackInterval.map((t) => t * TicksInSecond);
            exercisesPlayer.setTrackData(data);
            exercisesPlayer.setMediaRange(rangeInTicks);
            exercisesPlayer.setCurrentTime(rangeInTicks[0]);
        }
    );

    const [evaluateExercise] = useFetching(async () => {
        if (!comment) {
            setIsEmptyComment(true);
            return;
        }
        await ProgressService.evaluateExercise({
            studentId: currentStudent.id,
            exerciseId,
            status: StudentExerciseStatuses.Done,
            comment,
        });
        setStatus(StudentExerciseStatuses.Done);
        const { data } = await ProgressService.getNotifications({
            lang: i18n.language,
        });
        exercisesStore.setCurrentStudentChapters({
            ...currentStudentChapters,
            exercises: currentStudentChapters.exercises.map((e) =>
                e.id === exerciseId
                    ? { ...e, status: StudentExerciseStatuses.Done }
                    : e
            ),
        });
        studentsStore.setNotifications(data.items ?? []);
    });

    const getMediaPreview = () => {
        if (view === Chapters.Track) return null;
        if (exerciseObj.trackId && exerciseObj.trackInterval) {
            return (
                <div className={cl.videoContainer} key={exerciseObj.trackId}>
                    <ExercisePlayer isPreview={true} />
                    <ExercisePlayerControls mode={'video'} isPreview={true} />
                    {!hideEdit && (
                        <>
                            <p className={cl.textAlert}>
                                {t('exercises.exercise_preview_text_info')}
                            </p>
                            <div className={cl.exerciseText}>
                                {exerciseObj.text}
                            </div>
                        </>
                    )}
                </div>
            );
        }
        if (exerciseObj.text) {
            return <div className={cl.exerciseText}>{exerciseObj.text}</div>;
        }

        return null;
    };

    const getPreview = () => {
        switch (exerciseObj.type) {
            case GrammarExerciseTypes.Multichoice:
                return (
                    <MultichoicePreview
                        exerciseObj={exerciseObj}
                        results={results}
                        showAnswers={showAnswers}
                    />
                );
            case GrammarExerciseTypes.Substitution:
                return (
                    <SubstitutionPreview
                        exerciseObj={exerciseObj}
                        results={results}
                        showAnswers={showAnswers}
                    />
                );
            case GrammarExerciseTypes.Cloze:
                return (
                    <ClozePreview
                        exerciseObj={exerciseObj}
                        results={results}
                        showAnswers={showAnswers}
                    />
                );
            case MediaExerciseTypes.Quiz:
                return (
                    <QuizPreview
                        exerciseObj={exerciseObj}
                        results={results}
                        showAnswers={showAnswers}
                    />
                );
            case MediaExerciseTypes.SentencesOrder:
                return (
                    <SentenceOrderPreview
                        exerciseObj={exerciseObj}
                        showAnswers={showAnswers}
                        results={results}
                        setShowAnswers={exercisesStore.setShowAnswers}
                    />
                );
            case GrammarExerciseTypes.WordsOrder:
                return (
                    <WordsOrderPreview
                        exerciseObj={exerciseObj}
                        results={results}
                        showAnswers={showAnswers}
                    />
                );
            case MediaExerciseTypes.Summary:
                return (
                    <SummaryPreview
                        exerciseObj={exerciseObj}
                        results={results}
                        showAnswers={showAnswers}
                        setShowAnswers={exercisesStore.setShowAnswers}
                    />
                );
            case MediaExerciseTypes.AdvancedQuiz:
                return (
                    <AdvancedQuizPreview
                        exerciseObj={exerciseObj}
                        results={results}
                        showAnswers={showAnswers}
                    />
                );
            case MediaExerciseTypes.Presentation:
                return (
                    <FreeFormQuestionsPreview
                        exerciseObj={exerciseObj}
                        results={results}
                        showAnswers={showAnswers}
                    />
                );
            case MediaExerciseTypes.FreeFormQuestions:
                return (
                    <FreeFormQuestionsPreview
                        exerciseObj={exerciseObj}
                        results={results}
                        showAnswers={showAnswers}
                    />
                );
            case GrammarExerciseTypes.StatementsTransformation:
                return (
                    <FreeFormQuestionsPreview
                        exerciseObj={exerciseObj}
                        results={results}
                        showAnswers={showAnswers}
                    />
                );
            default:
                return <></>;
        }
    };

    const handleShowAnswers = () => {
        exercisesStore.setShowAnswers(!showAnswers);
    };

    const handleCommentChange = (text) => {
        setIsEmptyComment(false);
        setComment(text);
    };

    const renderResultsControl = () => {
        if (!results) return null;

        if (status === StudentExerciseStatuses.AwaitingEvaluation) {
            return (
                <>
                    <Input
                        placeholder={t('buttons.comment')}
                        variant={isEmptyComment ? 'erroredSmall' : 'filled'}
                        style={{ marginBottom: 12 }}
                        value={comment}
                        onChange={handleCommentChange}
                    />
                    <Button
                        text={t('exercises.evaluate')}
                        variant={Chapters.Exercise}
                        style={{ margin: '0 auto' }}
                        onClick={evaluateExercise}
                    />
                </>
            );
        }

        const text =
            status === StudentExerciseStatuses.Unfinished
                ? 'exercises.exercise_unfinished'
                : 'exercises.exercise_done';

        return <p className={`${cl.doneText} ${cl[status]}`}>{t(text)}</p>;
    };

    const handleEditButton = () => {
        exercisesStore.setEditMode(true);
    };

    const renderButtons = () => {
        if ((!isRenderEditButton() && !isExerciseHaveAnswers()) || results) {
            return null;
        }

        return (
            <div className={cl.buttons}>
                {isRenderEditButton() && (
                    <Button
                        text={t('buttons.edit')}
                        variant={'red'}
                        onClick={handleEditButton}
                    />
                )}
                {isExerciseHaveAnswers() && (
                    <Button
                        text={t(
                            showAnswers
                                ? 'buttons.reset'
                                : 'exercises.show_answers'
                        )}
                        variant={showAnswers ? 'dark' : 'grey'}
                        onClick={handleShowAnswers}
                    />
                )}
            </div>
        );
    };

    const syncChanges = (data) => {
        setExerciseObj(data);

        if (onChange) {
            const { id, title, difficulty } = data;
            onChange({ id, title, difficulty });
        }
    };

    useEffect(() => {
        setComment('');
        setExerciseObj({});
        if (!exerciseId) return;
        exercisesStore.setShowAnswers(false);
        setTimeout(() => {
            getExerciseData();
        }, 100);
    }, [exerciseId]);

    useEffect(() => {
        const currentStudentProgress = currentStudentChapters.exercises?.find(
            (e) => e.id === exerciseId
        );
        setResults(currentStudentProgress?.data ?? null);
        setStatus(currentStudentProgress?.status ?? '');
    }, [exerciseId, currentStudentChapters]);

    useEffect(() => {
        if (showAnswers) {
            scrollToBottom();
        }
    }, [showAnswers]);

    if (dataLoading || trackLoading)
        return <Loader style={{ margin: 'auto' }} />;

    return (
        <div className={cl.preview}>
            <div className={cl.container} ref={containerRef}>
                <p className={cl.exerciseTitle}>
                    {exerciseObj.title}
                    <span>{LanguageLevels[exerciseObj.difficulty]}</span>
                </p>
                <p className={cl.exerciseDescription}>
                    {exerciseObj.description}
                </p>

                <div className={cl.previewContainer}>
                    {getMediaPreview()}
                    {getPreview()}
                </div>
            </div>
            <div className={hideEdit ? cl.previewButtons : cl.buttonsContainer}>
                {renderResultsControl()}
                {renderButtons()}
            </div>
            {editMode && (
                <EditExerciseModal
                    currentExercise={exerciseObj}
                    onChange={syncChanges}
                />
            )}
        </div>
    );
};

export default observer(Preview);
