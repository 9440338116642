export function unselectSpan() {
    document
        .querySelectorAll('.selectedSpan')
        .forEach((span) => span.classList.remove('selectedSpan'));
}
export function selectSpan(elems) {
    if (!elems) return;
    if (!Array.isArray(elems)) elems = [elems];
    elems.forEach((el) => {
        el.classList.add('selectedSpan');
    });
}
