import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import player from '../../../../store/player';
import { PlayerControls } from '..';
import { TicksInSecond } from '../../../../data/common';
import cl from './Player.module.css';

const YTPlayer = require('yt-player');

const Player = ({ currentIndex, setCurrentIndex }) => {
    return (
        <div className={cl.wrapper}>
            {player.externalId ? <YouTubePlayer /> : null}
            <div className={cl.controls}>
                <PlayerControls
                    currentIndex={currentIndex}
                    setCurrentIndex={setCurrentIndex}
                />
            </div>
        </div>
    );
};

const YouTubePlayer = () => {
    const ref = useRef(null);

    const { externalId } = player;

    const onPlayerReady = () => {
        ref.current.load(
            player.externalId,
            false,
            player.currentTime / TicksInSecond
        );

        ref.current.on('paused', (e) => {
            player.setIsPlaying(false);
        });
        ref.current.on('cued', (e) => {
            player.setIsLoaded(true);
            player.handlePlayPauseClick(true);
        });
        ref.current.on('ended', () => {
            player.setIsPlaying(false);
        });
        ref.current.on('playing', () => {
            player.setIsPlaying(true);
        });
        ref.current.off('timeupdate', handleOnProgress);
        ref.current.on('timeupdate', handleOnProgress);
        ref.current.on('error', (err) => {
            console.error(err);
        });
        ref.current.seek(player.currentTime / TicksInSecond);
        player.setPlayerRef(ref.current);
        player.setIsPlaying(false);
    };

    const handleOnProgress = (time) => {
        if (time === 0) return;
        if (
            player.isRecognizing &&
            player.maxTime &&
            time * TicksInSecond > player.maxTime
        ) {
            player.handleChangeAudioTime(player.maxTime, true);
            player.playerRef.pause();
            return false;
        } else {
            player.handleChangeAudioTime(time);
        }
    };

    useEffect(() => {
        ref.current = new YTPlayer('#YTPlayer', {
            captions: false,
            controls: false,
            modestBranding: true,
            timeupdateFrequency: 100,
            playsInline: true,
            fullscreen: false,
            annotations: false,
            rel: 0,
            autoplay: true,
            related: false,
            disablekb: 0,
            iv_load_policy: 3,
            showinfo: 0,
            enablejsapi: 1,
        });
        onPlayerReady();
        return () => {
            player.destroy();
            ref.current?.destroy();
        };
    }, [externalId]);

    return <div id="YTPlayer" />;
};

export default observer(Player);
