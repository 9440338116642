import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import userWordsStore, {
    PackSizeOptions,
    PlayBackOptions,
    WordCardModes,
} from '../../pages/UserWords/store/userWords';
import { Button, Range, ToggleButton } from '../../../../teacherComponents';
import Modal from '../../../../components/UI/Modal/Modal';
import { Switch } from '../../../../UI';
import { DictionaryCards } from '..';
import { ReactComponent as IconSettings } from '../../../../assets/svg/settings.svg';
import cl from './LearnWords.module.css';

const Settings = observer(() => {
    const { t } = useTranslation();
    const [visible, setVisible] = useState(false);

    const { cardModes, wordsPerPack, playbackSpeed } = userWordsStore;

    const handleSettingToggle = (index) => {
        userWordsStore.toggleCardMode(index);
    };

    const handlePlaybackChange = (e) => {
        userWordsStore.setPlaybackSpeed(e.target.value);
    };

    return (
        <>
            <IconSettings
                className={cl.settingsButton}
                onClick={() => setVisible(true)}
            />

            <Modal
                className={cl.settingsModal}
                visible={visible}
                setVisible={() => setVisible(false)}
                withCloseButton
                style={{ zIndex: 11 }}
            >
                <p className={cl.settingsTitle}>
                    {t('glossary_settings.title')}
                </p>
                <div className={cl.settingsContainer}>
                    {Object.values(WordCardModes).map((mode, index) => (
                        <div className={cl.switchContainer} key={mode}>
                            <p className={cl.label}>
                                {t(`glossary_settings.${mode}`)}
                            </p>
                            <Switch
                                isOn={cardModes[index]}
                                handleToggle={() => handleSettingToggle(index)}
                                id={mode}
                            />
                        </div>
                    ))}
                </div>
                <p className={cl.toggleLabel}>
                    {t('glossary_settings.words_in_pack')}
                </p>
                <div className={cl.packSizeSelector}>
                    <ToggleButton
                        value={wordsPerPack}
                        onChange={userWordsStore.setCardsPerPack}
                        options={PackSizeOptions}
                        isGrid
                        variant={'transparent'}
                    />
                </div>
                <p> {t('glossary_settings.playback_speed')}</p>
                <Range
                    value={playbackSpeed}
                    min={PlayBackOptions[0]}
                    step={PlayBackOptions[1] - PlayBackOptions[0]}
                    max={PlayBackOptions[PlayBackOptions.length - 1]}
                    color={`var(--purple)`}
                    onChange={handlePlaybackChange}
                />
                <Button
                    variant={'dark'}
                    text={t('buttons.save')}
                    onClick={() => setVisible(false)}
                />
            </Modal>
        </>
    );
});

const LearnWords = () => {
    const { t } = useTranslation();

    const { dictionaryWords } = userWordsStore;

    const [learnMode, setLearnMode] = useState(false);
    const [isLearned, setIsLearned] = useState(null);

    const getClassName = () => {
        if (isLearned === true) return cl.continue;
        if (isLearned === false) return cl.repeat;
        return '';
    };

    return (
        <>
            {dictionaryWords.length !== 0 && (
                <Button
                    variant={'dark'}
                    text={t('glossary_settings.learn_words')}
                    className={cl.learnWords}
                    onClick={() => setLearnMode(true)}
                />
            )}
            <Modal
                className={`${cl.wordsModal} ${getClassName()}`}
                visible={learnMode}
                setVisible={() => setLearnMode(false)}
                withCloseButton
            >
                <DictionaryCards
                    words={dictionaryWords}
                    isLearned={isLearned}
                    setIsLearned={setIsLearned}
                />
                <Settings />
            </Modal>
        </>
    );
};

export default observer(LearnWords);
