import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from '../../../../../teacherComponents';
import replaceTrailingPunctuation from '../../../../../utils/replaceTrailingPunctuation';
import cl from './WriteCard.module.css';

const WriteCard = ({ wordObj }) => {
    const { t } = useTranslation();

    const [currentIdx, setCurrentIdx] = useState(0);
    const [isCorrect, setIsCorrect] = useState(null);
    const [text, setText] = useState('');
    const [isBlur, setIsBlur] = useState(true);

    const timeoutRef = useRef();

    const examples = wordObj.examples.map((eObj) => eObj.example);
    const translations = wordObj.examples.map((eObj) => eObj.translation);

    const checkAnswer = () => {
        const formattedAnswer = replaceTrailingPunctuation(text).toLowerCase();
        const formattedSentence = replaceTrailingPunctuation(
            examples[currentIdx]
        ).toLowerCase();

        const flag = formattedAnswer === formattedSentence;
        setIsCorrect(flag);
        if (flag) {
            handleNextSentence();
        }
    };

    const handleNextSentence = () => {
        if (currentIdx < examples.length - 1) {
            timeoutRef.current = setTimeout(() => {
                setCurrentIdx(currentIdx + 1);
            }, 2000);
        } else {
            setCurrentIdx(currentIdx + 1);
        }
    };

    const getTranslationClassname = (idx) => {
        const classnames = [cl.translationText];
        if (currentIdx === idx) classnames.push(cl.active);
        if ((currentIdx === idx && isCorrect) || currentIdx > idx)
            classnames.push(cl.correct);
        return classnames.join(' ');
    };

    useEffect(() => {
        clearTimeout(timeoutRef.current);
        setCurrentIdx(0);
    }, [wordObj]);

    useEffect(() => {
        setText('');
        setIsBlur(true);
    }, [currentIdx]);

    useEffect(() => {
        if (examples[currentIdx]) {
            checkAnswer();
        } else {
            setIsCorrect(null);
        }
    }, [text, examples, currentIdx]);

    return (
        <div className={cl.writeCard}>
            <div className={cl.sentences}>
                {translations.map((translation, idx) => (
                    <Fragment key={translation}>
                        <p className={getTranslationClassname(idx)}>
                            {translation}
                        </p>
                        {idx === currentIdx && !isCorrect && (
                            <p
                                className={`${cl.exampleText} ${
                                    isBlur ? cl.blur : ''
                                }`}
                                onClick={() => setIsBlur(!isBlur)}
                            >
                                {examples[idx]}
                            </p>
                        )}
                        {idx === currentIdx && !isCorrect && (
                            <Input
                                variant={'outlined'}
                                placeholder={t('user_view.input_phrase')}
                                value={text}
                                onChange={setText}
                                style={{ margin: '20px 0' }}
                            />
                        )}
                    </Fragment>
                ))}
            </div>
        </div>
    );
};

export default WriteCard;
