import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import replaceTrailingPunctuation from '../../../../../../utils/replaceTrailingPunctuation';
import shuffleArray from '../../../../../../utils/shuffleArray';
import cl from './ExerciseMode.module.css';

const ExerciseMode = ({ statement }) => {
    const { sentences, translate } = statement;
    const translatedSentecnes = translate.split('. ');
    const mappedStatement = {
        examples: sentences.map((s, i) => ({
            example: s.text,
            translation: translatedSentecnes[i],
        })),
    };

    const [currentIdx, setCurrentIdx] = useState(0);
    const [isCorrect, setIsCorrect] = useState(null);
    const [wordsAmount, setWordsAmount] = useState(0);
    const [answers, setAnswers] = useState([]);
    const [options, setOptions] = useState([]);

    const timeoutRef = useRef();

    const examples = mappedStatement.examples.map((eObj) => eObj.example);
    const translations = mappedStatement.examples.map(
        (eObj) => eObj.translation
    );
    const sentence = replaceTrailingPunctuation(examples[currentIdx] || '');

    const handleWordClick = (wObj) => {
        if (!wObj) return;
        if (!answers.some((w) => w.idx === wObj.idx)) {
            setAnswers([...answers, wObj]);
            setOptions(options.filter((w) => w.idx !== wObj.idx));
        } else {
            setAnswers(answers.filter((w) => w.idx !== wObj.idx));
            setOptions([...options, wObj]);
        }
    };

    const checkAnswer = () => {
        const flag =
            answers
                .map((wObj) => wObj.word)
                .join(' ')
                .toLowerCase() === sentence.toLowerCase();
        setIsCorrect(flag);
        if (flag) {
            handleNextSentence();
        }
    };

    const handleNextSentence = () => {
        timeoutRef.current = setTimeout(() => {
            setCurrentIdx(currentIdx + 1);
        }, 2000);
    };

    useEffect(() => {
        clearTimeout(timeoutRef.current);
        setCurrentIdx(0);
    }, [statement]);

    useEffect(() => {
        setAnswers([]);
        if (!sentence.length) {
            setOptions([]);
            setWordsAmount(0);
            return;
        }
        const optionsArr = shuffleArray(sentence.split(' ')).map(
            (word, idx) => ({
                word,
                idx,
            })
        );
        setOptions(optionsArr);
        setWordsAmount(optionsArr.length);
    }, [currentIdx]);

    useEffect(() => {
        if (answers.length === wordsAmount) {
            checkAnswer();
        } else {
            setIsCorrect(null);
        }
    }, [answers]);

    return (
        <motion.div
            className={cl.card}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, ease: 'easeOut' }}
        >
            <div className={cl.translationList}>
                {examples
                    .filter((_e, i) => i < currentIdx)
                    .map((text, idx) => (
                        <p
                            className={`${cl.translationText} ${
                                currentIdx > idx
                                    ? cl.correct
                                    : currentIdx === idx
                                      ? cl.active
                                      : ''
                            }`}
                            key={text}
                        >
                            {text}
                        </p>
                    ))}
            </div>
            <div
                className={`${cl.answerCont} ${
                    isCorrect !== null
                        ? isCorrect
                            ? cl.correct
                            : cl.wrong
                        : ''
                }`}
            >
                {Array(wordsAmount)
                    .fill('')
                    .map((_w, idx) => (
                        <span
                            className={cl.word}
                            style={{
                                minWidth: answers[idx] ? 'unset' : 75,
                            }}
                            key={`answer_${idx}`}
                            onClick={() => handleWordClick(answers[idx])}
                        >
                            {answers[idx]?.word || ''}
                        </span>
                    ))}
            </div>
            <div className={cl.translationList}>
                {translations
                    .filter((_e, i) => i >= currentIdx)
                    .map((text, idx) => (
                        <p
                            className={`${cl.translationText}  ${idx === 0 ? cl.active : ''}`}
                            key={text}
                        >
                            {text}
                        </p>
                    ))}
            </div>
            {translations[currentIdx] && (
                <div className={cl.optionsCont}>
                    {options.map((wObj, idx) => (
                        <span
                            className={cl.word}
                            key={`option_${idx}`}
                            onClick={() => handleWordClick(wObj)}
                        >
                            {wObj.word}
                        </span>
                    ))}
                </div>
            )}
        </motion.div>
    );
};

export default ExerciseMode;
