import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { mapChapterData } from '../../../../pages/TeacherContent/helpers/mapChapterData';
import DemoPageLoader from '../../../../components/UI/DemoPageLoader/DemoPageLoader';
import { Chapters } from '../../../../pages/TeacherLessons/data/constants';
import {
    ExerciseIcon,
    ExerciseMediaIcon,
} from '../../../../pages/TeacherContent/views/ExercisesView/components';
import { ReactComponent as IconFolder } from '../../../../assets/svg/lessons-folder.svg';
import { MediaExerciseTypes } from '../../../../pages/TeacherContent/data/constants';
import cl from './StudentChapterCard.module.css';

const StudentChapterCard = ({ chapter, type, isActive, setActive }) => {
    const { t } = useTranslation();

    const [isLoaded, setIsLoaded] = useState(false);
    const [mappedChapter, setMappedChapter] = useState({});
    const { title, preview, params = [] } = mappedChapter;

    const handleChapterClick = () => {
        if (!setActive || isActive || chapter.pending) return;
        setActive(chapter);
    };

    const getChapterClassName = () =>
        `${cl.chapterCard} ${isActive ? cl.active : ''}  ${cl[type]} ${chapter.pending ? cl.pending : ''}`;

    const renderWordCount = () => {
        const count =
            chapter?.wordsCount ?? chapter?.words?.length ?? [].length;

        return Number.isNaN(count) ? '' : count;
    };

    const getMediaType = () => {
        if (chapter.trackId && chapter.trackInterval) return 'video';
        if (Object.values(MediaExerciseTypes).includes(chapter.exerciseType))
            return 'text';
        if (chapter.text) return 'text';
        return chapter.exerciseType || chapter.type;
    };

    useEffect(() => {
        setMappedChapter(mapChapterData(chapter, type, t));
    }, [chapter]);

    return (
        <div className={getChapterClassName()} onClick={handleChapterClick}>
            {type !== Chapters.Dictionary && (
                <div className={cl.imageContainer}>
                    {preview && (
                        <>
                            {!isLoaded && (
                                <DemoPageLoader
                                    size={12}
                                    color={'var(--white)'}
                                />
                            )}
                            <img
                                src={preview}
                                alt={title}
                                onLoad={() => setIsLoaded(true)}
                            />
                        </>
                    )}
                    {type === Chapters.Dictionary && (
                        <>
                            <div className={cl.folder}>
                                <IconFolder />
                            </div>
                            <p className={cl.count}>{renderWordCount()}</p>
                        </>
                    )}
                    {type === Chapters.Exercise && (
                        <>
                            <ExerciseIcon
                                type={chapter.exerciseType || chapter.type}
                            />
                            <ExerciseMediaIcon type={getMediaType()} />
                        </>
                    )}
                </div>
            )}
            {type === Chapters.Dictionary ? (
                <div className={cl.wordCard}>
                    <p className={cl.title}>{title}</p>
                    {chapter.pending && (
                        <DemoPageLoader
                            className={cl.wordLoader}
                            size={12}
                            color={'var(--black)'}
                        />
                    )}
                </div>
            ) : (
                <div className={cl.cardInner}>
                    <p className={cl.title}>{title}</p>
                    <div className={cl.infoContainer}>
                        {params.map((p, i) => (
                            <p className={cl.label} key={i}>
                                {p}
                            </p>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default observer(StudentChapterCard);
