import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { authStore } from '../../../../App';
import player from '../../../../store/player';
import languagesStore from '../../../../store/interface';
import DictionaryService from '../../../../api/DictionaryService';
import ProgressService from '../../../../api/ProgressService';
import TranslationService from '../../../../api/TranslationService';
import { Roles, TicksInSecond } from '../../../../data/common';
import { selectSpan, unselectSpan } from '../../../../utils/selectUnselectSpan';
import replaceTrailingPunctuation from '../../../../utils/replaceTrailingPunctuation';
import cl from './TextDisplay.module.css';

const TextDisplay = ({ currentIndex, setCurrentIndex }) => {
    const { lang, nativeLang } = languagesStore;
    const { role } = authStore;

    const contRef = useRef();
    const textRef = useRef();
    const currentSentence = player.sentences[currentIndex];

    const handleWordClick = async (e, offset) => {
        const classNames = e.currentTarget.className;
        if (classNames && classNames.toLowerCase().includes('selectedspan')) {
            player.setHighlightTranslation(null);
            unselectSpan();
            return;
        }
        unselectSpan();
        selectSpan(e.target);
        const formattedString = formatSelectionString([e.target]);
        const translation = await translateHighlightPart(
            formattedString,
            offset
        );
        player.setHighlightTranslation(translation);

        if (role === Roles.User) saveWordToDictionary(formattedString);
    };

    const formatSelectionString = (arr) => {
        const firstSelected = arr[0];
        const lastSelected = arr[arr.length - 1];
        let start;
        let end;
        const words = arr[0].offsetParent.childNodes;
        words.forEach((w, i) => {
            if (w === firstSelected) start = i;
            if (w === lastSelected) end = i;
        });
        const a = [...words].map((e) => e.innerText);
        const formattedString = `${a.slice(0, start).join(' ')} <span>${a
            .slice(start, end + 1)
            .join(' ')}</span> ${a.slice(end + 1).join(' ')}`
            .replace(/ +/g, ' ')
            .trim();
        return formattedString;
    };

    const translateHighlightPart = async (formattedString, offset) => {
        if (player.isPlaying) {
            player.handlePlayPauseClick(false);
        }
        const { data } = await TranslationService.translateSentence({
            text: formattedString,
            sourceLang: lang,
            targetLang: nativeLang,
        });
        return data.targetText;
    };

    const saveWordToDictionary = async (sentence) => {
        const matches = /<span>(.*?)<\/span>/g.exec(sentence);
        if (!matches || !matches.length) return;

        const word = replaceTrailingPunctuation(matches[1]);
        const { data } = await DictionaryService.getWordByText({
            word,
            sourceLang: lang,
            targetLang: nativeLang,
        });

        const words = data.data || [];
        if (!words.length) return;

        const id = words[0].id;
        await ProgressService.addFavoriteWord({
            id,
            word,
            lang,
        });
    };

    const isShowText = () => {
        if (!currentSentence) return false;
        if (!player.isPlaying) return true;
        if (currentSentence.words) {
            const { currentTime } = player;
            const lastWord = currentSentence.words.slice(-1)[0];
            const { offset, duration } = lastWord;
            if (currentTime >= offset + duration) return false;
        }
        return true;
    };

    const renderCurrentSentence = () => {
        if (!currentSentence || !currentSentence.words) return;
        return currentSentence.words.map((word, i) => {
            const { text, offset } = word;
            const classNames = [cl.word];
            if (word.active === true) {
                classNames.push(cl.active);
            }
            return (
                <span
                    key={`${text}_${offset}_${i}`}
                    onClick={(e) => handleWordClick(e, offset)}
                    className={classNames.join(' ')}
                >
                    {word.text}
                </span>
            );
        });
    };

    const pauseOnSentenceEnd = () => {
        if (!currentSentence || !currentSentence.words) return;
        if (currentSentence.duration <= TicksInSecond / 5 || !player.isPlaying)
            return;
        const { currentTime } = player;
        const lastWord = currentSentence.words.slice(-1)[0];
        const { offset, duration } = lastWord;
        const nextSentenceOffset =
            player.sentences[currentIndex + 1]?.offset || 0;
        const extraTime =
            nextSentenceOffset - (offset + duration) < TicksInSecond / 10
                ? TicksInSecond / 5
                : 0;
        if (currentTime + extraTime >= offset + duration) {
            player.setWasPaused(true);
            player.handlePlayPauseClick(false);
        }
    };

    useEffect(() => {
        if (player.pauseAfterEach && !player.wasPaused) {
            pauseOnSentenceEnd();
        }
        const newIndex = player.sentences.map((e) => e.active).indexOf(true);
        if (newIndex === currentIndex) player.setWasForwarded(false);
        if (player.wasForwarded) return;
        if (
            newIndex !== currentIndex ||
            !player.currentSentenceObj ||
            player.currentSentenceObj.key !== player.sentences[newIndex].key
        ) {
            player.setCurrentSentenceObj(player.sentences[newIndex]);
            player.setWasPaused(false);
            setCurrentIndex(newIndex);
        }
    }, [player.currentTime]);

    useEffect(() => {
        if (player.isPlaying) {
            unselectSpan();
            player.setHighlightTranslation(null);
        }
    }, [player.isPlaying]);

    useEffect(() => {
        player.setWasPaused(false);
        player.setHighlightTranslation(null);
    }, [currentIndex]);

    return (
        <div ref={contRef} className={cl.textDisplay}>
            <div
                className={cl.currentSentence}
                ref={textRef}
                style={{
                    opacity: isShowText() ? 1 : 0,
                }}
            >
                {renderCurrentSentence()}
            </div>
        </div>
    );
};
export default observer(TextDisplay);
