import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import languagesStore from '../../../../../store/interface';
import userWordsStore from '../../../pages/UserWords/store/userWords';
import DictionaryService from '../../../../../api/DictionaryService';
import { useFetching } from '../../../../../hooks/useFetching';
import { Button } from '../../../../../teacherComponents';
import { ReactComponent as IconSound } from '../../../../../assets/svg/icon-sound.svg';
import cl from './InfoCard.module.css';

const InfoCard = ({ wordObj }) => {
    const { t } = useTranslation();

    const { playbackSpeed } = userWordsStore;
    const { nativeLang } = languagesStore;
    const [fetchedWord, setFetchedWord] = useState({});
    const [audio] = useState(new Audio());

    const wordData = wordObj.pronunciation ? wordObj : fetchedWord;

    const {
        word,
        pronunciation,
        examples,
        synonyms,
        translations,
        partOfSpeech,
        forms,
        voicePath,
    } = wordData;

    const [getWord, isLoading] = useFetching(async () => {
        const { data } = await DictionaryService.getWordByText({
            word: wordObj.word,
            sourceLang: wordObj.lang,
            targetLang: nativeLang,
        });
        setFetchedWord({ ...data, ...data.data[0] });
    });

    const handleAudioClick = () => {
        setTimeout(() => {
            audio.playbackRate = playbackSpeed;
            audio.play();
        });
    };

    useEffect(() => {
        if (wordObj.id && !wordObj.pronunciation) getWord();
    }, [wordObj]);

    useEffect(() => {
        if (voicePath) {
            audio.pause();
            audio.src = voicePath;
        } else {
            audio.src = '';
        }
    }, [voicePath]);

    if (!wordData.id) return null;

    return (
        <div className={cl.theoryCard}>
            <div className={cl.mainInfo}>
                <Button
                    className={cl.playButton}
                    icon={<IconSound />}
                    variant={'grey'}
                    onClick={handleAudioClick}
                />
                <p className={cl.wordText}>{word}</p>
                <p className={cl.wordInfo}>{pronunciation}</p>
                {translations && (
                    <p className={cl.infoText}>{translations.join(', ')}</p>
                )}
                {partOfSpeech && (
                    <p className={cl.partOfSpeech}>{partOfSpeech}</p>
                )}
            </div>
            <div className={cl.additionalInfo}>
                {forms.length ? (
                    <div className={cl.infoCont}>
                        <p className={cl.infoLabel}>{t('demo_page.forms')}</p>
                        <p className={cl.infoText}>{forms.join(', ')}</p>
                    </div>
                ) : null}
                {synonyms.length ? (
                    <div className={cl.infoCont}>
                        <p className={cl.infoLabel}>
                            {t('demo_page.synonyms')}
                        </p>
                        <p className={cl.infoText}>{synonyms.join(', ')}</p>
                    </div>
                ) : null}
                {examples.length ? (
                    <div className={cl.infoCont}>
                        <p className={cl.infoLabel}>{t('demo_page.phrases')}</p>
                        {examples.map((eObj, i) => (
                            <div key={eObj.id}>
                                <p className={cl.infoText}>{eObj.example}</p>
                                <p className={cl.infoTranslation}>
                                    {eObj.translation}
                                </p>
                            </div>
                        ))}
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default observer(InfoCard);
