import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Typo from 'typo-js';
import exercisesStore from '../../store/exercisesStore';
import onboardingStore from '../../../../store/onboarding';
import wordsStore from '../../../../store/words';
import { useFetching } from '../../../../hooks/useFetching';
import ProgressService from '../../../../api/ProgressService';
import {
    CategoryWords,
    CreateWordsCategoryModal,
    GenerateWordsCategoryModal,
    SelectDictionaryModal,
} from './components';
import {
    ChapterPlug,
    ChapterPreviewContainer,
    ChaptersList,
    ContentContainer,
} from '../../components';
import {
    ChapterModals,
    Chapters,
    SelectWordsCategoryModalType,
} from '../../../TeacherLessons/data/constants';
import { LanguageLevels } from '../../../../data/common';
import {
    OnboardingModal,
    OnboardingKeys,
} from '../../../../teacherComponents/Onboarding';
import { WordStatuses } from './data/constants';

const DictionaryView = ({ isModal, alreadyAdded, onSelect }) => {
    const { t, i18n } = useTranslation();
    const lang = i18n.language;

    const { difficulty, chaptersModalType, dictionarySearch, unassigned } =
        exercisesStore;

    const { processedWords } = wordsStore;

    const isOnboarding = onboardingStore.isOnboarding(
        OnboardingKeys.DictionaryAwaitSelect
    );

    const [dictionary, setDictionary] = useState();

    const [categories, setCategories] = useState([]);
    const [filteredCategories, setFilteredCategories] = useState([]);

    const [currentCategory, setCurrentCategory] = useState(null);
    const [currentWord, setCurrentWord] = useState(null);

    const [addedWord, setAddedWord] = useState('');

    const [isFilter, setIsFilter] = useState(false);

    const [getCategories, isLoading] = useFetching(async () => {
        const { data } = await ProgressService.getUserCategories({
            unassigned,
        });

        if (!data?.items) {
            setCategories([]);
            return;
        }

        const currentLangCategories = data.items.filter((c) => c.lang === lang);

        const categoriesWithWords = await Promise.all(
            currentLangCategories.map(async (c) => {
                const { data: wordsData } =
                    await ProgressService.getCategoryWords({
                        categoryId: c.id,
                        lang,
                    });
                return {
                    ...c,
                    words: wordsData.items,
                    wordsCount: wordsData.items?.length ?? '?',
                };
            })
        );

        const withoutExisting = alreadyAdded?.length
            ? categoriesWithWords.filter(
                  (e) => !alreadyAdded.some((a) => a.id === e.id)
              )
            : categoriesWithWords;

        setCategories(withoutExisting || []);
    });

    const [addCategory, addLoading] = useFetching(async (category) => {
        const { id } = category;
        await ProgressService.cloneWordsCategory({
            id,
            lang,
        });
        setCategories([category, ...categories]);
        setCurrentCategory(category);
        if (isOnboarding) {
            onboardingStore.moveToNextComponent();
        }
    });

    const [deleteActiveWord] = useFetching(async () => {
        if (!currentWord?.word) return;

        const { word } = currentWord;

        await ProgressService.deleteCategoryWord({
            categoryId: currentCategory.id,
            word,
        });

        setCurrentWord();
        handleCategoryUpdate({
            ...currentCategory,
            wordsCount: currentCategory.wordsCount - 1,
        });
    });

    const handleGeneratedCategoryAdd = (addedCategory, generatedWords) => {
        handleCategoryAdd(addedCategory);
        for (const word of generatedWords) {
            wordsStore.addWord({
                word,
                categoryId: addedCategory.id,
                status: WordStatuses.Queued,
            });
        }
    };

    const handleCategoryChange = (category) => {
        setCurrentCategory(null);
        setTimeout(() => {
            setCurrentCategory(category);
        });
    };

    const handleCategorySelect = onSelect
        ? () => {
              if (!currentCategory) return;
              const { id, title } = currentCategory;
              onSelect({ id, title });
          }
        : null;

    const handleCategoryAdd = (addedCategory) => {
        const words = [];

        setCategories([{ ...addedCategory, words }, ...categories]);
        setCurrentCategory({ ...addedCategory, words });
    };

    const handleCategoryDelete = (deletedId) => {
        if (currentCategory.id === deletedId) {
            setCurrentCategory();
            setCurrentWord();
        }
        setCategories(categories.filter((c) => c.id !== deletedId));
    };

    const handleCategoryUpdate = (updatedCategory) => {
        setCategories(
            categories.map((c) =>
                c.id === updatedCategory.id ? updatedCategory : c
            )
        );
        if (currentCategory.id === updatedCategory.id) {
            setCurrentCategory(updatedCategory);
        }
    };

    const deleteWord = (pendingWord) => {
        pendingWord
            ? wordsStore.deletePendingWord(pendingWord)
            : deleteActiveWord();
    };

    const handleWordAdd = ({ word, categoryId }, isNew) => {
        if (!word);
        if (isNew) setAddedWord('');
        wordsStore.addWord({ word, categoryId, status: WordStatuses.Queued });
    };

    useEffect(() => {
        getCategories();
    }, [unassigned]);

    useEffect(() => {
        if (!currentCategory?.id) return;
        setAddedWord('');
    }, [currentCategory?.id]);

    useEffect(() => {
        setAddedWord('');
    }, [currentWord]);

    useEffect(() => {
        if (filteredCategories.some((c) => c.id === currentCategory?.id))
            return;
        setCurrentCategory(filteredCategories[0]);

        if (!filteredCategories[0] || !filteredCategories[0].words) return;
        setCurrentWord(filteredCategories[0].words[0]);
    }, [filteredCategories]);

    useEffect(() => {
        const filteredByCategories = categories.filter((c) => {
            const matchesSearch = dictionarySearch
                ? c.title.toLowerCase().includes(dictionarySearch.toLowerCase())
                : true;

            const matchesDifficulty = difficulty
                ? c.level === LanguageLevels.indexOf(difficulty)
                : true;

            return matchesSearch && matchesDifficulty;
        });

        setFilteredCategories(filteredByCategories);
        setIsFilter(difficulty || dictionarySearch);
    }, [dictionarySearch, difficulty, categories]);

    useEffect(() => {
        const typoDictionary = new Typo('en_US', false, false, {
            dictionaryPath: '/dictionaries',
        });
        setDictionary(typoDictionary);
    }, [lang]);

    useEffect(() => {
        if (!currentCategory?.id) {
            setCurrentWord(null);
        } else {
            setCurrentWord(
                currentCategory?.words ? currentCategory?.words[0] : null
            );
        }
    }, [currentCategory?.id]);


    return (
        <ContentContainer view={isModal ? '' : Chapters.Dictionary}>
            <ChaptersList
                type={Chapters.Dictionary}
                isLoading={isLoading}
                chapters={filteredCategories}
                current={currentCategory}
                onDelete={handleCategoryDelete}
                setCurrent={handleCategoryChange}
                onSelect={handleCategorySelect}
                isFilter={isFilter}
            />
            {currentCategory ? (
                <ChapterPreviewContainer>
                    <CategoryWords
                        dictionary={dictionary}
                        category={currentCategory}
                        currentWord={currentWord}
                        setCurrentWord={setCurrentWord}
                        addedWord={addedWord}
                        setAddedWord={setAddedWord}
                        onAdd={handleWordAdd}
                        onDelete={deleteWord}
                        onUpdate={handleCategoryUpdate}
                    />
                </ChapterPreviewContainer>
            ) : (
                <ChapterPlug />
            )}
            <GenerateWordsCategoryModal
                lang={lang}
                visible={
                    chaptersModalType === ChapterModals[Chapters.Dictionary]
                }
                setVisible={exercisesStore.setChaptersModalType}
                onAdd={handleGeneratedCategoryAdd}
            />
            <CreateWordsCategoryModal
                lang={lang}
                visible={chaptersModalType === SelectWordsCategoryModalType}
                setVisible={exercisesStore.setChaptersModalType}
                onAdd={handleCategoryAdd}
            />
            <SelectDictionaryModal
                visible={chaptersModalType === Chapters.Dictionary}
                setVisible={exercisesStore.setChaptersModalType}
                onClick={addCategory}
                alreadyAdded={categories}
                isOnboarding={isOnboarding}
            />
            <OnboardingModal
                onboardingKey={onboardingStore.isOnboarding(
                    OnboardingKeys.DictionaryInfo
                )}
                title={t('teacher_onboarding.dictionary_info')}
                subtitle={t('teacher_onboarding.dictionary_info_subtitle')}
                delay={500}
            />
        </ContentContainer>
    );
};

export default observer(DictionaryView);
