import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import languagesStore from '../../../../../../store/interface';
import userDialoguesStore from '../../../../pages/UserDialogues/store/userDialogues';
import { useFetching } from '../../../../../../hooks/useFetching';
import RecognitionService from '../../../../../../api/RecognitionService';
import { AudioRecorder, ClickableAudio } from '../../..';
import { Button } from '../../../../../../teacherComponents';
import replaceTrailingPunctuation from '../../../../../../utils/replaceTrailingPunctuation';
import { URL } from '../../../../../../api/SituationsService';
import { ReactComponent as IconSound } from '../../../../../../assets/svg/icon-sound.svg';
import cl from './SpeakMode.module.css';

const SpeakMode = ({ statement, translationMode = false }) => {
    const { t } = useTranslation();

    const { sentences, translate } = statement;
    const translatedSentences = translate.split('. ');

    const { lang } = languagesStore;
    const { playbackSpeed } = userDialoguesStore;

    const [mappedStatement, setMappedStatement] = useState({});
    const [currentIdx, setCurrentIdx] = useState(0);
    const [recognized, setRecognized] = useState('');
    const [isRecording, setIsRecording] = useState(false);
    const [isCorrect, setIsCorrect] = useState(null);
    const [isError, setIsError] = useState(false);
    const [blurText, setBlurText] = useState(true);
    const [audio] = useState(new Audio());

    const timeoutRef = useRef();

    const examples =
        mappedStatement.examples?.map((eObj) => eObj.example) || [];
    const examplesVoicePath =
        mappedStatement.examples?.map((eObj) => `${URL}${eObj.voicePath}`) ||
        [];
    const translations =
        mappedStatement.examples?.map((eObj) => eObj.translation) || [];

    const [recognizeAudio, isRecognitionLoading] = useFetching(async (blob) => {
        const formData = new FormData();
        formData.append('file', blob);
        formData.append('language', lang);
        const { data } = await RecognitionService.recognizeAudio(formData);
        const { text } = data;

        if (text) {
            setRecognized(text);
        } else {
            setIsError(true);
        }
    });

    const handleAudioClick = () => {
        setTimeout(() => {
            audio.playbackRate = playbackSpeed;
            audio.play();
        });
    };

    const handleRecordingStart = () => {
        setIsError(false);
        setIsCorrect(null);
        setRecognized('');
        setIsRecording(true);
    };

    const handleRecordingStop = (blob) => {
        setIsRecording(false);
        recognizeAudio(blob);
    };

    const compareRecognized = () => {
        const recognizedToCompare = recognized
            .match(/\p{L}+/gu)
            .join('')
            .toLowerCase();
        const sentence = replaceTrailingPunctuation(examples[currentIdx] || '');
        const sentenceToCompare = sentence.length
            ? sentence
                  .match(/\p{L}+/gu)
                  .join('')
                  .toLowerCase()
            : '';
        // TODO: improve comparing algorithm @saratovkin
        if (recognizedToCompare === sentenceToCompare) {
            setIsCorrect(true);
            timeoutRef.current = setTimeout(() => {
                setCurrentIdx(currentIdx + 1);
            }, 2000);
        } else {
            setIsCorrect(false);
        }
    };

    const renderText = () => {
        if (isError) {
            return (
                <p className={cl.errorAlert}>{t('demo_page.no_recognition')}</p>
            );
        }
        if (isRecognitionLoading || isRecording)
            return (
                <div className={cl.dotsCont}>
                    <div className={cl.dots} />
                </div>
            );
        if (!recognized) return <></>;
        let checkClassname = '';
        if (isCorrect !== null) {
            checkClassname = isCorrect ? cl.correct : cl.wrong;
        }
        return (
            <p className={`${cl.recognizedText} ${checkClassname}`}>
                {recognized}
            </p>
        );
    };

    useEffect(() => {
        setMappedStatement({
            examples: sentences.map((s, i) => ({
                example: s.text,
                translation: translatedSentences[i],
                voicePath: s.sentenceVoice,
            })),
        });
    }, [sentences]);

    useEffect(() => {
        clearTimeout(timeoutRef.current);
        setCurrentIdx(0);
        setBlurText(true);
    }, [mappedStatement]);

    useEffect(() => {
        setIsCorrect(null);
        setRecognized('');
        if (examplesVoicePath[currentIdx]) {
            audio.pause();
            audio.src = examplesVoicePath[currentIdx];
        } else {
            audio.src = '';
        }
    }, [currentIdx, mappedStatement]);

    useEffect(() => {
        if (recognized) {
            compareRecognized();
        }
    }, [recognized]);

    return (
        <motion.div
            className={cl.card}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, ease: 'easeOut' }}
        >
            {currentIdx > 2 ? (
                <div className={cl.resultsCont}>
                    {Array(3)
                        .fill('')
                        .map((_, i) => (
                            <div className={cl.sentenceResult} key={i}>
                                <ClickableAudio
                                    className={cl.audioBtn}
                                    src={`${examplesVoicePath[i]}`}
                                >
                                    <IconSound className={cl.soundIcon} />
                                </ClickableAudio>
                                <p className={cl.text}>{examples[i]}</p>
                                <p className={cl.translation}>
                                    {translations[i]}
                                </p>
                            </div>
                        ))}
                </div>
            ) : (
                <>
                    <Button
                        className={cl.playButton}
                        icon={<IconSound />}
                        variant={'grey'}
                        onClick={handleAudioClick}
                    />
                    <p className={cl.sentence}>
                        {translationMode
                            ? translations[currentIdx]
                            : examples[currentIdx]}
                    </p>
                    <p
                        className={`${cl.translation} ${blurText ? cl.blur : ''}`}
                        onClick={() => setBlurText(!blurText)}
                    >
                        {translationMode
                            ? examples[currentIdx]
                            : translations[currentIdx]}
                    </p>
                    <div className={cl.recognitionBlock}>
                        {renderText()}
                        <div
                            className={cl.audioBtnCont}
                            style={{ opacity: isCorrect ? 0 : 1 }}
                        >
                            <AudioRecorder
                                key={statement}
                                onStart={handleRecordingStart}
                                onStop={handleRecordingStop}
                            />
                        </div>
                    </div>
                </>
            )}
        </motion.div>
    );
};

export default observer(SpeakMode);
