import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { authStore } from '../../../../App';
import languagesStore from '../../../../store/interface';
import userWordsStore, { WordStatuses } from './store/userWords';
import { useFetching } from '../../../../hooks/useFetching';
import DictionaryService from '../../../../api/DictionaryService';
import ProgressService from '../../../../api/ProgressService';
import PageWrapper from '../../../../components/UI/PageWrapper/PageWrapper';
import {
    Input,
    ToggleButton,
    Toolbar,
    ViewTitle,
} from '../../../../teacherComponents';
import {
    ContentContainer,
    LearnWords,
    UserChapterPreview,
} from '../../components';
import ChaptersList from '../../components/ChaptersList/ChaptersList';
import { Chapters } from '../../../../pages/TeacherLessons/data/constants';
import { ReactComponent as IconWords } from '../../../../assets/svg/lessons-dictionary.svg';
import cl from './UserWords.module.css';

const WordRequestInterval = 2000;
const Timeout = 20000;

const UserWords = ({ categoryId }) => {
    const { t } = useTranslation();
    const { lang, nativeLang } = languagesStore;

    const { user } = authStore;

    const {
        currentWord,
        page,
        pendingWords,
        searchString,
        status,
        dictionaryWords,
    } = userWordsStore;

    const [getAllWords, isLoading] = useFetching(async () => {
        const { data } = await ProgressService.getWords({
            lang: user.lang,
        });
        const currentPendingWords = [];

        await Promise.all(
            data.items.map(async (item) => {
                const { data } = await DictionaryService.getWordByText({
                    word: item.word,
                    sourceLang: lang,
                    targetLang: nativeLang,
                });
                if (!data) {
                    currentPendingWords.push(item);
                }
            })
        );

        userWordsStore.setWordsData(data.items);

        if (currentPendingWords.length > 0) {
            processPendingWords(currentPendingWords);
        }
    });

    const [getCategoryWords] = useFetching(async () => {
        const { data } = await ProgressService.getCategoryWords({
            categoryId,
            lang: user.lang,
        });
        const currentPendingWords = [];

        await Promise.all(
            data.items.map(async (item) => {
                const { data } = await DictionaryService.getWordByText({
                    word: item.word,
                    sourceLang: lang,
                    targetLang: nativeLang,
                });
                if (!data) {
                    currentPendingWords.push(item);
                }
            })
        );

        userWordsStore.setWordsData(
            data.items.map((w) => ({ ...w, status: WordStatuses.Active }))
        );

        if (currentPendingWords.length > 0) {
            processPendingWords(currentPendingWords);
        }
    });

    const getWords = categoryId ? getCategoryWords : getAllWords;

    const processPendingWords = async (currentPendingWords) => {
        userWordsStore.setPendingWords(currentPendingWords);

        for (const word of currentPendingWords) {
            try {
                await DictionaryService.translateWord({
                    word: word.word,
                    sourceLang: lang,
                    targetLang: nativeLang,
                });
                const startTime = Date.now();

                while (Date.now() - startTime < Timeout) {
                    const { data } = await DictionaryService.getWordByText({
                        word: word.word,
                        sourceLang: lang,
                        targetLang: nativeLang,
                    });

                    if (data) {
                        userWordsStore.removePendingWord(word.wordId);
                        break;
                    }

                    await new Promise((resolve) =>
                        setTimeout(resolve, WordRequestInterval)
                    );
                }
            } catch (error) {
                console.error(
                    `Error processing pending word: ${word.word}`,
                    error
                );
            }
        }
    };

    const getWordsByStatus = () =>
        dictionaryWords
            .map((w) =>
                pendingWords.some(
                    (pW) => pW.wordId === w.id || pW.word === w.word
                )
                    ? { ...w, pending: true }
                    : w
            )
            .filter((w) => w.status === status);

    useEffect(() => {
        getWords();
        userWordsStore.setCurrentWord({});
    }, [page, lang, nativeLang]);

    useEffect(() => {
        userWordsStore.setCurrentWord({});
        if (page !== 0) {
            userWordsStore.setPage(0);
        } else {
            getWords();
        }
    }, [searchString]);

    useEffect(() => {
        userWordsStore.setCurrentWord({});
    }, [status]);

    return (
        <PageWrapper
            additionalClass={`${cl.wordsWrapper} ${categoryId ? cl.modal : ''}`}
        >
            {!categoryId && (
                <>
                    <ViewTitle
                        text={t('user_view.my_words')}
                        icon={<IconWords />}
                    />
                    <Toolbar>
                        <Input
                            value={searchString}
                            onChange={userWordsStore.setSearchString}
                            placeholder={t('user_view.search')}
                        />
                    </Toolbar>
                </>
            )}

            <ContentContainer className={cl.wordsList}>
                <div className={cl.chaptersContainer}>
                    <ChaptersList
                        chapters={getWordsByStatus()}
                        type={Chapters.Dictionary}
                        current={currentWord}
                        setCurrent={userWordsStore.setCurrentWord}
                        isLoading={isLoading}
                    />
                    {!categoryId && dictionaryWords.length !== 0 && (
                        <div className={cl.modesContainer}>
                            <ToggleButton
                                variant={'transparent'}
                                isGrid
                                value={status}
                                onChange={userWordsStore.setStatus}
                                options={Object.values(WordStatuses)}
                                withTranslations={'user_view'}
                                className={cl.modeToggle}
                            />
                        </div>
                    )}
                    <LearnWords />
                </div>

                <UserChapterPreview
                    chapter={currentWord}
                    type={Chapters.Dictionary}
                />
            </ContentContainer>
        </PageWrapper>
    );
};

export default observer(UserWords);
