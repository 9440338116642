import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import StudentChapterCard from '../StudentChapterCard/StudentChapterCard';
import { Loader } from '../../../../UI';
import { ReactComponent as IconEyes } from '../../../../assets/svg/lessons-eyes.svg';
import cl from './ChaptersList.module.css';

const ChaptersList = ({
    current,
    setCurrent,
    chapters,
    type,
    onScroll,
    isLoading,
}) => {
    const { t } = useTranslation();

    return (
        <div className={cl.chaptersContainer}>
            {chapters.length ? (
                <div className={cl.list} onScroll={onScroll}>
                    {chapters.map((chapter) => (
                        <StudentChapterCard
                            key={chapter.id}
                            type={type}
                            chapter={chapter}
                            isActive={current?.id === chapter.id}
                            setActive={setCurrent}
                        />
                    ))}
                </div>
            ) : isLoading ? (
                <div className={cl.emptyAlert}>
                    <Loader style={{ opacity: 0.2 }} />
                </div>
            ) : (
                <div className={cl.emptyAlert}>
                    <IconEyes />
                    <p className={cl.alertTitle}>
                        {t(`user_view.empty_${type}_title`)}
                    </p>
                    <p className={cl.alertSubtitle}>
                        {t(`user_view.empty_${type}_subtitle`)}
                    </p>
                </div>
            )}
        </div>
    );
};

export default observer(ChaptersList);
