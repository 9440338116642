import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import player from '../../../../store/player';
import { useFetching } from '../../../../hooks/useFetching';
import TrackService from '../../../../api/TrackService';
import { Player, TranslationDisplay, TextDisplay } from '..';
import cl from './PonyPlayer.module.css';

const PonyPlayer = ({ trackId }) => {
    const { i18n } = useTranslation();
    const { language: lang } = i18n;

    const [currentIndex, setCurrentIndex] = useState(0);

    const [getTrack, isLoading] = useFetching(async ({ id }) => {
        const { data } = await TrackService.getTrackSentences({ id, lang });
        player.parseTrackData(data);
    });

    useEffect(() => {
        getTrack({ id: trackId });
    }, [trackId]);

    return (
        <div className={cl.trackPlayer} key={trackId}>
            <TranslationDisplay currentIndex={currentIndex} />
            <Player
                key={trackId}
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
            />
            <TextDisplay
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
            />
        </div>
    );
};

export default observer(PonyPlayer);
